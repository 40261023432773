// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  game_history: `Lịch sử trò chơi`,
  customer_service: `Dịch vụ khách hàng`,
  name: `Tên`,
  time: `Thời gian`,
  bet_amount: `Số tiền cược`,
  login: `Đăng nhập`,
  register: `Đăng ký`,
  captcha: `CAPTCHA`,
  password: 'Mật khẩu',
  forgot_password: `Quên mật khẩu`,
  minor_note: `Để truy cập trang web này, bạn cần phải trên 18 tuổi và đồng ý với`,
  terms_of_service: `Điều khoản dịch vụ`,
  sign_up: `Đăng ký`,
  username: `Tên đăng nhập`,
  email: `Email`,
  full_name: `Họ và tên`,
  confirm_18: `Tôi xác nhận rằng tôi đã 18 tuổi và tôi đã đọc`,
  share: `Chia sẻ`,
  deposit: `Nạp tiền`,
  bonus: `Tiền thưởng`,
  app: `Ứng dụng`,
  load_more: `Tải thêm`,
  details: `Chi tiết`,
  helps: `HỖ TRỢ`,
  faq: `Câu hỏi thường gặp`,
  amount: `Số tiền`,
  extra: `Thêm`,
  deposit_bonus: `Tiền thưởng nạp tiền`,
  do_not_participate: `Không tham gia khuyến mãi`,
  payment_problem: `Vấn đề thanh toán`,
  click_for_help: `Nhấn để được trợ giúp`,
  deposit_notes: `Ghi chú nạp tiền`,
  available_balance: `Số dư khả dụng`,
  withdraw: `Rút tiền`,
  withdrawal_notes: `Ghi chú rút tiền`,
  withdrawal_note1: `Số lần rút tiền trong hiện tại`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Phí rút tiền:`,
  min: `Tối thiểu:`,
  max: `Tối đa:`,
  notifications: `Thông báo`,
  invalid_x: `{{x}} không hợp lệ`,
  captcha_not_match: `Captcha không khớp`,
  invalid_x_must_be_atleast_y_characters: `{{x}} không hợp lệ, phải có ít nhất {{y}} ký tự`,
  x_is_required: `{{x}} là bắt buộc`,
  phone_number: 'Số điện thoại',
  phone_num_x_max: `Số điện thoại tối đa {{x}} chữ số`,
  email_sent: `Email đã được gửi, vui lòng kiểm tra email của bạn.`,
  enter_valid_email: `Vui lòng nhập email hợp lệ.`,
  confirm: `Xác nhận`,
  password_atleast_6_characters: `Mật khẩu phải có ít nhất 6 ký tự.`,
  password_reset_success: `Đặt lại mật khẩu thành công!.`,
  new_password: `Mật khẩu mới`,
  bank: 'Ngân hàng',
  bank_transfer: `Chuyển khoản ngân hàng`,
  bank_name: `Tên ngân hàng`,
  account_name: `Tên tài khoản`,
  account_number: `Số tài khoản`,
  drop_or_select: `Kéo hoặc chọn tập tin`,
  drop_files_here: `Kéo các tập tin vào đây`,
  transfer_to: `Chuyển khoản đến`,
  upload_receipt: `Tải hóa đơn lên`,
  select_bonus: `Chọn tiền thưởng`,
  no_promotion: `Không có khuyến mãi`,
  please_upload_receipt: `Vui lòng tải hóa đơn lên`,
  bank_account: `Tài khoản ngân hàng`,
  add_account: `Thêm tài khoản`,
  account_holder_name: `Tên chủ tài khoản`,
  name_of_the_account_holder: `Tên của chủ tài khoản`,
  fill_in_bank_account: `Điền thông tin tài khoản ngân hàng`,
  withdraw_information: `Thông tin rút tiền`,
  note: `Ghi chú`,
  amount_withdrawn: `Số tiền đã rút`,
  amount_no_exceed_balance: `Số tiền rút không được vượt quá số dư khả dụng`,
  minimum_withdraw_amount_is_x: `Số tiền rút tối thiểu là {{x}}`,
  transaction_history: `Lịch sử giao dịch`,
  date_from: `Ngày từ`,
  date_to: `Ngày đến`,
  account_name_must_be_4: `Tên tài khoản phải dài hơn 4 ký tự`,
  account_number_must_be_x: `Số tài khoản phải từ 10-14 ký tự`,
  minimum_length_is_x: `Độ dài tối thiểu là {{x}}`,
  maximum_length_is_x: `Độ dài tối đa là {{x}}`,
  payment_method: `Phương thức thanh toán`,
  mode: `Chế độ`,
  no_bank_account: `Bạn không có tài khoản nào để rút tiền, Thêm ngay`,
  please_select_bank: `Vui lòng chọn một tài khoản ngân hàng`,

  bet_history: `Lịch sử cược`,
  game_provider: `Nhà cung cấp trò chơi`,
  bet_count: `Số lượng cược`,
  win_loss: `Thắng/Thua`,
  rebate: `Hoàn tiền`,
  turnover: `Doanh thu`,
  game_category: `Danh mục trò chơi`,

  you_have_x_unread_messages: `Bạn có {{x}} tin nhắn chưa đọc`,
  deposit_amount_between_x_and_y: `Số tiền nạp phải nằm giữa {{x}} và {{y}}`,
  minimum_amount_is_x_y: `Số tiền tối thiểu là {{x}}{{y}}`,

  // all
  all: `Tất cả`,
  title: `Tiêu đề`,
  date: `Ngày`,
  type: `Loại`,
  status: `Trạng thái`,
  remark: `Ghi chú`,
  category: `Danh mục`,
  please_select_x: `Vui lòng chọn {{x}}`,
  save: `Lưu`,

  change_password: `Đổi mật khẩu`,
  current_password: `Mật khẩu hiện tại`,
  confirm_password: `Xác nhận mật khẩu mới`,
  submit: `Gửi`,
  password_changed: `Mật khẩu đã được thay đổi`,
  x_must_be_y_characters: `{{x}} phải có ít nhất {{y}} ký tự`,
  reset_password: `Đặt lại mật khẩu`,
  clear: `Xóa`,
  search: `Tìm kiếm`,
  new: `Mới`,
  logout: `Đăng xuất`,
  choose_date: `Chọn ngày`,
  select_both_date: `Chọn cả ngày từ và đến`,
  from: `Từ`,
  to: `Đến`,
  apply: `Áp dụng`,
  promotions: `Khuyến mãi`,
  learn_more: `TÌM HIỂU THÊM`,
  password_doesnt_match: `Mật khẩu không khớp`,
  cancel: `Hủy`,
  confirm_to_pay: `Xác nhận thanh toán`,
  deposit_amount: `Số tiền nạp`,
  loading_please_wait: `Đang tải, vui lòng chờ`,
  account: `Tài khoản`,
  profile: `Hồ sơ`,
  bank_list: `Danh sách ngân hàng`,
  in_maintenance: `Đang bảo trì`,
  birthday: `Ngày sinh`,
  birthday_bonus: `Nhập ngày sinh của bạn để nhận tiền thưởng! 🎉🎁`,
  referral_code: `Mã giới thiệu`,
  referral: `Giới thiệu`,
  my_referral: `Giới thiệu của tôi`,
  my_claim: `Yêu cầu của tôi`,
  claim_profit: `YÊU CẦU LỢI NHUẬN`,
  available_profit: `Lợi nhuận khả dụng`,
  claimed_profit: `Lợi nhuận đã yêu cầu`,
  all_time_profit: `Lợi nhuận mọi thời đại`,
  referral_note_on_x: `Nhận lợi nhuận mỗi khi bạn bè của bạn chơi trên {{x}}`,
  tier: `Cấp bậc`,
  commission: `Hoa hồng`,
  referred_member: `Thành viên được giới thiệu`,
  profit_earned: `Lợi nhuận kiếm được`,
  account_information: `Thông tin tài khoản`,
  invite_friends: `Mời bạn bè`,
  how_it_work: `Hoạt động như thế nào?`,
  invite_note: `Nhận tiền thưởng giới thiệu độc quyền khi bạn bè của bạn đăng ký bằng mã giới thiệu của bạn!`,
  share_via: `Chia sẻ qua`,
  referral_link: `Liên kết giới thiệu`,
  total_profit: `Tổng lợi nhuận`,
  copied: `Đã sao chép`,
  nothing_to_claim: `Không có gì để yêu cầu`,
  claim_history: `Lịch sử yêu cầu`,
  downline: `Danh sách cấp dưới`,
  last_x_digit_bank_ref_number: `3 ký tự cuối của số tham chiếu ngân hàng`,
  bank_ref_number_validation_x: `Số tham chiếu ngân hàng là bắt buộc và phải có độ dài {{x}} ký tự`,
  sort: 'Sắp xếp',

  refer_and_reward: `Giới thiệu & Thưởng`,
  linked_successfully_x: `Liên kết thành công với {{x}}! Vui lòng đăng ký tài khoản của bạn lần đầu`,
  more_x_of_y: `Thêm {{x}}/{{y}}`,
  default: `Mặc định`,
  promotion: `Khuyến mãi`,
  daily_mission: 'Nhiệm vụ hàng ngày',
  agent: 'Đại lý',
  contact_us: 'Liên hệ với chúng tôi',
  download: 'Tải xuống',
  cash_claim: 'Yêu cầu tiền mặt',
  demo: 'Dùng thử',
  favorites: 'Yêu thích',
  popular: 'Phổ biến',
  claim_bonus: `Yêu cầu tiền thưởng`,
  insufficient_balance: `Số dư không đủ`,
  currently_displaying: 'Hiện đang hiển thị {{x}} {{y}} trò chơi trong số {{z}}',
  all_x: 'Tất cả {{x}}',
  no_results: 'Không tìm thấy kết quả',
  search_games: 'Tìm kiếm trò chơi',
  please_scan_using_your: `Vui lòng quét bằng`,
  vip_level: `Cấp VIP`,
  bonus_direct: `TIỀN THƯỞNG TRỰC TIẾP`,
  rebate_level: `Cấp hoàn tiền`,
  vip_header: `Tất cả các cược đặt trên Máy đánh bạc, Trò chơi bảng và thẻ, Câu cá, Video, Thể thao và Trò chơi xổ số tại HalongBay sẽ được tích lũy vĩnh viễn. Cược hợp lệ = Thu nhập vĩnh viễn và Tài chính!`,
  vip_example: `
  Ví dụ 1: Các thành viên đạt 1 triệu tiền gửi tích lũy, đạt cấp VIP 2, nhận tiền thưởng 210 R$, và hoàn tiền 3.50%. Khi họ đạt 6 triệu tiền gửi tích lũy, họ đạt cấp VIP 3 và nhận tiền thưởng 800 R$, với hoàn tiền 4%`,
  activity_rules: `III. Quy tắc hoạt động:`,
  activity_rules_1: `1. Tất cả tiền thưởng cần phải có x3 Winover để yêu cầu rút tiền`,
  activity_rules_2: `2. Vui lòng yêu cầu tiền thưởng hoàn tiền trong thời gian quy định, nếu bạn chậm trễ, sẽ bị xem là từ bỏ;`,
  activity_rules_3: `3. Tham gia hoạt động của các thành viên được hệ thống tự động tính toán, nếu có bất kỳ khiếu nại nào, kết quả điều tra của HalongBay sẽ được ưu tiên;`,
  activity_rules_4: `4. Nếu bạn quên tài khoản/mật khẩu thành viên, vui lòng liên hệ với "Dịch vụ khách hàng trực tuyến 24 giờ" để được trợ giúp phục hồi thông tin tài khoản;`,
  activity_rules_5: `5. HalongBay bảo lưu quyền thay đổi, dừng hoặc hủy bỏ chương trình khuyến mãi này bất cứ lúc nào.`,
  activity_rules_6: `6. Không lưu trò chơi miễn phí, Mua trò chơi miễn phí`,
  activity_rules_7: `7. Không thể kết hợp với bất kỳ tiền thưởng nào`,
  activity_rules_8: `8. Số tiền rút tối đa cho mỗi lần nạp là 100.000 R$`,
  member: `Thành viên`,
  return_home: `Trở về trang chủ`,
  more: `Thêm`,
  earn: `Kiếm`,
  deposit_method: `Phương thức nạp tiền`,
  next: `Tiếp theo`,
  my_account: `Tài khoản của tôi`,
  level: `Cấp bậc`,
  providers: `Nhà cung cấp`,
  home: `Trang chủ`,
  member_center: `Trung tâm thành viên`,
  adjustment: `Điều chỉnh`,
  no_balance: `Không có số dư`,
  withdraw_amount: `Số tiền rút`,
  lucky_draw: `Rút thăm may mắn`,
  services: `Dịch vụ`,
  fast_transaction: `Giao dịch nhanh`,
  fast_transaction_desc: `Chúng tôi cung cấp nhiều kênh nạp tiền, ví không phí chuyển khoản tự động, quy trình rút tiền đơn giản và khả năng đạt được thời gian nạp tiền, rút tiền và chuyển khoản cực nhanh. Công nghệ tối ưu hóa mạng độc quyền giảm độ trễ mạng để mang đến trải nghiệm chơi game vượt trội.`,
  safety_guaranteed: `Bảo mật được đảm bảo`,
  safety_guaranteed_desc: `Chúng tôi sử dụng các thiết bị kiểm tra bảo mật internet nổi tiếng quốc tế để kiểm tra nghiêm ngặt, cũng như công nghệ mã hóa 128-Bit, để đảm bảo quyền riêng tư và bảo mật dữ liệu của bạn. Thông tin cá nhân của bạn sẽ không bao giờ được tiết lộ hoặc bán cho bên thứ ba. HalongBay, một nền tảng giải trí trực tuyến có giấy phép`,
  game_options: `Tùy chọn trò chơi`,
  game_options_desc: `Chúng tôi mang đến cho bạn hàng nghìn sự kiện thể thao thú vị như bóng đá, bóng rổ, e-sport, quần vợt, snooker, và nhiều hơn nữa, tất cả đều theo thời gian thực! Cũng có các trò chơi khác để lựa chọn, chẳng hạn như casino trực tuyến, xổ số, poker, câu cá, và máy đánh bạc.`,
  multi_platform: `Đa nền tảng`,
  multi_platform_desc: `Với công nghệ tiên tiến, HalongBay phát triển một bộ ứng dụng độc lập và hoàn chỉnh. Bạn hiện có thể thưởng thức web, H5, ứng dụng iOS và Android tích hợp, cho phép bạn tìm kiếm giải trí và đặt cược bất cứ khi nào và ở đâu. Dịch vụ khách hàng 24/7 cung cấp dịch vụ tận tâm và hiệu quả.`,
  hot_games: `Trò chơi hot`,
  sports: `Thể thao`,
  casino: `Casino`,
  slots: `Máy đánh bạc`,
  fishing: `Câu cá`,
  cards: `Thẻ`,
  games: `Trò chơi`,
  terms_n_conditions: `CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN`,
  claim_now: `YÊU CẦU NGAY`,
  the_activity_x_in: `Hoạt động {{x}} Trong`,
  starts: `Bắt đầu`,
  ends: `Kết thúc`,
  list_of_winners: `DANH SÁCH NGƯỜI THẮNG`,
  days: `Ngày`,
  hours: `Giờ`,
  minutes: `Phút`,
  seconds: `Giây`,
  please_login: `Vui lòng đăng nhập`,
  promo_concluded: `Khuyến mãi đã kết thúc`,
  bonus_claimed: `Tiền thưởng đã yêu cầu`,
  can_only_claim: `Chỉ có thể yêu cầu tiền thưởng từ {{x}} đến {{y}} theo giờ Malaysia`,
  total_amount_received: `Tổng số tiền đã nhận`,
  deposit_fee: `Phí nạp tiền`,
  get: 'Nhận',
  before_that: 'Trước đó',
  remarks: 'Nhận xét',
  nothing_to_show: 'Không có gì để trình bày',
  dont_show_again: 'Không hiện lại nữa',
  back: 'Quay lại',
  close: 'Đóng',
  select_bank_source: `Chọn nguồn ngân hàng`,
  payment_draft:
    'Nếu bạn xác nhận rằng bạn đã thanh toán, vui lòng đợi một lúc vì thanh toán có thể vẫn đang được xử lý',
  monthly_tier_retention_requirement: `Yêu cầu duy trì cấp hàng tháng`,
  monthly_accumulated_deposit: `Tiền gửi tích lũy hàng tháng`,
  monthly_turnover_requirement: `Yêu cầu doanh thu hàng tháng`,
  vip_level_maintain_period: `Thời gian duy trì cấp độ VIP`,
  special_rebate: `Giảm giá đặc biệt`,
  slot_rebate: `Giảm giá máy đánh bạc`,
  live_casino_rebate: `Hoàn trả sòng bạc trực tiếp`,
  vip_benefits: `Lợi ích VIP`,
  vip_bonus: `Tiền thưởng VIP`,
  birthday_cash: `Tiền mặt sinh nhật`,
  withdraw_limit: `Giới hạn rút tiền`,
  vip: `VIP`,
};

export default vn;
